<script setup lang="ts">
import { type PropType } from 'vue'
import { computed, toRefs, ref } from 'vue'
import IconUser from '../../icons/IconUser.vue'
import type { User } from '../../../interfaces/user'
import type { Partner } from '../../../interfaces/partner'
import type { Network, CommunityNetwork } from '../../../interfaces/network'

const emits = defineEmits(['closeMobileMenu', 'updateSize'])
const props = defineProps({
    user: {
        type: Object as PropType<User>,
        required: true
    },
    isPartner: {
        type: Boolean,
        default: false
    },
    currentPartner: {
        type: Object as PropType<Partner>,
        default: undefined
    },
    currentNetwork: {
        type: Object as PropType<Network>,
        default: undefined
    },
    currentCommunityNetwork: {
        type: Object as PropType<CommunityNetwork>,
        default: undefined
    },
    isSuperAdmin: {
        type: Boolean,
        default: false
    },
    logoName: {
        type: String,
        default: 'LogoOps'
    },
    isBig: {
        type: Boolean,
        default: true
    }
})

const isBig = ref(props.isBig)

const {
    user,
    isPartner,
    currentPartner,
    currentNetwork,
    currentCommunityNetwork,
    isSuperAdmin,
    logoName
} = toRefs(props)

const userFullName = computed(() => user.value?.fullName || '')
const userEmail = computed(() => user.value?.email || '')
const iconName = computed(() =>
    props.isPartner ? 'IconChargemapPartners' : 'IconChargemapBusiness'
)

const linkGoToIndex = computed(() => {
    if (isPartner.value) {
        if (isSuperAdmin.value) {
            return '/partners'
        }

        if (currentPartner?.value && currentNetwork?.value) {
            return `/partners/${currentPartner?.value.id}/networks/interop/${currentNetwork?.value.id}/dashboard`
        }

        if (currentPartner?.value && currentCommunityNetwork?.value) {
            return `/partners/${currentPartner?.value.id}/networks/community/${currentCommunityNetwork?.value.id}/dashboard`
        }

        return '/login'
    }

    return '/'
})

function closeMobileMenu() {
    emits('closeMobileMenu')
}

const toggleMenuSize = () => {
    isBig.value = !isBig.value
    emits('updateSize')
}
</script>

<template>
    <div class="chargemap-menu-wrapper">
        <div
            class="chargemap-menu"
            :class="isBig && 'chargemap-menu__big'"
            data-testId="chargemap-menu"
        >
            <div class="chargemap-menu__container">
                <div
                    class="flex sticky top-0 bg-primary-700 z-20 justify-between transition-all ease-in-out duration-500 pb-6"
                    :class="!isBig && 'flex-col gap-4'"
                >
                    <div class="h-12">
                        <!-- This is only to keep a space for the transition -->
                    </div>
                    <div
                        class="absolute flex justify-left h-12 items-center transition-all ease-in-out duration-300"
                        :class="
                            isBig
                                ? 'opacity-100'
                                : 'opacity-0 pointer-events-none'
                        "
                        style="transition: opacity 0.5s"
                        @click="closeMobileMenu"
                    >
                        <component
                            :is="logoName"
                            class="w-40"
                            :link="linkGoToIndex"
                        />
                    </div>
                    <nuxt-link
                        :to="linkGoToIndex"
                        class="absolute flex justify-left items-center chargemap-logo"
                    >
                        <div
                            class="flex justify-center w-12 transition-all ease-in-out duration-300"
                            :class="
                                isBig
                                    ? 'opacity-0 pointer-events-none'
                                    : 'opacity-100'
                            "
                            style="transition: opacity 0.5s"
                        >
                            <component :is="iconName" />
                        </div>
                    </nuxt-link>

                    <div
                        class="hidden md:flex top-8 justify-center p-3 hover:bg-primary-600 rounded-md w-12 cursor-pointer"
                        @click="toggleMenuSize"
                    >
                        <component
                            :is="isBig ? 'IconChevronLeft' : 'IconChevronRight'"
                            class="w-6 text-primary-300"
                        />
                    </div>

                    <div
                        class="md:hidden flex justify-center p-3 hover:bg-primary-600 rounded-md w-12 cursor-pointer"
                        @click="closeMobileMenu"
                    >
                        <IconChevronLeft class="w-6 text-primary-300" />
                    </div>
                </div>

                <div v-if="$slots['context-actions']" class="w-full">
                    <slot name="context-actions" />
                </div>

                <div class="h-0 border-t border-primary-500 mt-6" />

                <nav class="main-nav overflow-auto py-4 relative">
                    <div
                        class="flex flex-col gap-4 items-center w-full"
                        data-testId="menu-section"
                    >
                        <slot :is-big="isBig" />
                    </div>
                </nav>
            </div>

            <div
                v-if="user"
                class="px-4 flex flex-col border-t border-primary-500"
            >
                <nuxt-link
                    class="block bottom-link hover:bg-primary-600 rounded-md mt-1"
                    :class="!isBig && 'w-12'"
                    to="/profile"
                    @click="closeMobileMenu"
                >
                    <div
                        class="flex gap-3 items-center py-3 h-12"
                        :class="
                            isBig ? 'px-1.5 justify-start' : 'justify-center'
                        "
                    >
                        <div
                            class="flex items-center justify-center rounded-full min-w-9 min-h-9 w-9 h-9 text-slate-500 border bg-white border-slate-300"
                        >
                            <IconUser class="w-6 h-6" />
                        </div>
                        <div
                            v-if="isBig"
                            class="flex flex-col text-white overflow-hidden"
                        >
                            <p class="font-medium whitespace-nowrap">
                                {{ userFullName }}
                            </p>
                            <p
                                class="whitespace-nowrap overflow-hidden text-ellipsis"
                            >
                                {{ userEmail }}
                            </p>
                        </div>
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
.chargemap-menu-wrapper {
  @apply h-full md:w-fit
      w-4/5 pointer-events-none
      p-2;
}

.chargemap-menu {
  @apply flex flex-col flex-auto
      justify-between
      relative
      z-0
      rounded-2xl
      pointer-events-auto
      w-full
      pt-4
      pb-4
      h-full
      bg-primary-700
      overflow-hidden
      md:w-20
      md:border-zinc-300
      transition-all
      ease-in-out duration-500;
}
.chargemap-menu__big {
  @apply md:w-72;
}
.chargemap-menu__container {
  @apply flex flex-initial flex-col overflow-hidden;
  max-height: calc(100vh - 4.3rem);
}
.chargemap-menu__container > * {
  @apply px-4;
}
.chargemap-menu .main-nav {
  scrollbar-track-color: transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.chargemap-menu .main-nav::-webkit-scrollbar {
  display: none;
}
.chargemap-menu .bottom-link {
  @apply text-sm cursor-pointer overflow-hidden;
}
</style>
